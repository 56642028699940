import fetch from "@/utils/fetch";
const Gateway = "/order"; // 订单服务
const ApiPath = "/api";
const PathPer = ApiPath + Gateway;
/**
 * get 示例 post 改 method  body传参方式 用 data:query
 * /
// export function get(query) {
//     return fetch({
//         url: PathPer + '',
//         method: 'get',
//         params: query
//     });
// }
/**
 * 文件上传示例
 */
// export function upload(files){
//     return fetch({
//         url: PathPer + '/evaluation/file/upload',
//         method: 'post',
//         headers: {
//             "Content-Type": "multipart/form-data"
//         },
//         data: files
//     })
// }
/**
 * 产品加入购物车
 */
export function saveCarInfo(query) {
  return fetch({
    url: PathPer + "/sc/save",
    method: "post",
    params: query,
  });
}
/**
 * 购物车列表

 */
export function carListInfo(query) {
  return fetch({
    url: PathPer + "/sc/list",
    method: "get",
    params: query,
  });
}
/**
 * 购物车数量
 */
export function carCountInfo(query) {
  return fetch({
    url: PathPer + "/sc/count",
    method: "get",
    params: query,
  });
}
/**
 * 根据id删除购物车
 */
export function carDelInfo(query) {
  return fetch({
    url: PathPer + "/sc/del",
    method: "post",
    params: query,
  });
}

// 收货地址
/**
 * 地址列表
 */
export function addressListInfo(query) {
  return fetch({
    url: PathPer + "/address/fp",
    method: "get",
    params: query,
  });
}
/**
 * 添加收货地址
 */
export function saveAddressInfo(query) {
  return fetch({
    url: PathPer + "/address/save",
    method: "post",
    params: query,
  });
}
/**
 * 根据id查询地址

 */
export function findAddressInfo(query) {
  return fetch({
    url: PathPer + "/address/findById",
    method: "get",
    params: query,
  });
}
/**
 * 删除地址信息
 */
export function delAddressInfo(query) {
  return fetch({
    url: PathPer + "/address/del",
    method: "post",
    params: query,
  });
}
/**
 * 修改地址信息
 */
export function updateAddressInfo(query) {
  return fetch({
    url: PathPer + "/address/update",
    method: "post",
    params: query,
  });
}
/**
 * 保存客户订单
 */
export function saveOrderInfo(query) {
  return fetch({
    url: PathPer + "/co/save",
    method: "post",
    params: query,
  });
}
/**
 * 订单列表
 */
export function orderListInfo(query) {
  return fetch({
    url: PathPer + "/cot/list",
    method: "get",
    params: query,
  });
}
/**
 * 订单预览---购物车去结算
 */
export function previewInfo(query) {
  return fetch({
    url: PathPer + "/co/preview",
    method: "get",
    params: query,
  });
}
/**
 * 保存客户订单
 */
export function submitOrderInfo(query) {
  return fetch({
    url: PathPer + "/co/save",
    method: "post",
    params: query,
  });
}
/**
 * 查看客户订单
 */
export function findByIdOrderInfo(query) {
  return fetch({
    url: PathPer + "/cot/findById",
    method: "get",
    params: query,
  });
}
/**
 * 添加优先发货明细
 */
export function addPriorityInfo(query) {
  return fetch({
    url: PathPer + "/co/addPriority",
    method: "post",
    params: query,
  });
}
/**
 * 优先发货清单
 */
export function getPriorityListInfo(query) {
  return fetch({
    url: PathPer + "/co/priorityList",
    method: "get",
    params: query,
  });
}
/**
 * 删除优先发货明细
 */
export function delPriorityListInfo(query) {
  return fetch({
    url: PathPer + "/co/delPriority",
    method: "post",
    params: query,
  });
}
/**
 * 客户的补货清单
 */
export function getRepListInfo(query) {
  return fetch({
    url: PathPer + "/cr/fp",
    method: "get",
    params: query,
  });
}
/**
 * 可补货库存表

 */
export function getCanRepListInfo(query) {
  return fetch({
    url: PathPer + "/cr/list",
    method: "get",
    params: query,
  });
}
/**
 * 加入补货清单
 */
export function addRepListInfo(query) {
  return fetch({
    url: PathPer + "/cr/save",
    method: "post",
    params: query,
  });
}
/**
 * 根据id删除补货清单
 */
export function delRepListInfo(query) {
  return fetch({
    url: PathPer + "/cr/del",
    method: "post",
    params: query,
  });
}
/**
 * 发货计划支付页-尾款
 */
export function paymentDetailInfo(query) {
  return fetch({
    url: PathPer + "/cot/paymentDetail",
    method: "get",
    params: query,
  });
}
/**
 * 优先发货总数
 */
export function priorityCountInfo(query) {
  return fetch({
    url: PathPer + "/co/count",
    method: "get",
    params: query,
  });
}
/**
 * 补货清单总数量
 */
export function repCountInfo(query) {
  return fetch({
    url: PathPer + "/cr/count",
    method: "get",
    params: query,
  });
}
/**
 * 删除优先发货明细
 */
export function delPriorityInfo(query) {
  return fetch({
    url: PathPer + "/co/delPriority",
    method: "post",
    params: query,
  });
}
/**
 * 取消客户订单
 */
export function cancelOrderInfo(query) {
  return fetch({
    url: PathPer + "/order/cancel",
    method: "post",
    params: query,
  });
}
/**
 * 退货原因列表
 */
export function cancelReasonInfo(query) {
  return fetch({
    url: PathPer + "/remark/list",
    method: "get",
    params: query,
  });
}
/**
 * 发货单确认收货
 */
export function confirmOrderInfo(query) {
  return fetch({
    url: PathPer + "/order/confirm",
    method: "post",
    params: query,
  });
}

/**
 * 关结订单
 */
export function coFinishInfo(query) {
  return fetch({
    url: PathPer + "/co/finishOrder",
    method: "post",
    params: query,
  });
}
// /**
//  * 客户/商家订单列表

//  */
// export function getOrderListInfo(query) {
//   return fetch({
//     url: PathPer + "/order/list",
//     method: "get",
//     params: query,
//   });
// }
/**
 * 客户订单列表

 */
export function getOrderListCustomerInfo(query) {
  return fetch({
    url: PathPer + "/order/list/customer",
    method: "get",
    params: query,
  });
}
/**
 * 商家订单列表

 */
export function getOrderListSupplierInfo(query) {
  return fetch({
    url: PathPer + "/order/list/supplier",
    method: "get",
    params: query,
  });
}
/**
 * 客户订单详情

 */
export function getOrderDetailListInfo(query) {
  return fetch({
    url: PathPer + "/order/detail",
    method: "get",
    params: query,
  });
}
/**
 * 客户订单&供应商-我的
 */
export function getMyOrderInfo(query) {
  return fetch({
    url: PathPer + "/order/myOrder",
    method: "get",
    params: query,
  });
}
/**
 * 客户端查询订单数
 */
export function getMyOrderNumInfo(query) {
  return fetch({
    url: PathPer + "/order/coc",
    method: "get",
    params: query,
  });
}

/**
 * 供应商查询订单数
 */
export function getSuppOrderNumInfo(query) {
  return fetch({
    url: PathPer + "/order/soc",
    method: "get",
    params: query,
  });
}
/**
 * 佣金详情
 */
export function commissionDetailInfo(query) {
  return fetch({
    url: PathPer + "/deo/commission",
    method: "get",
    params: query,
  });
}
/**
 * 仓储费详情
 */
export function storageDetailInfo(query) {
  return fetch({
    url: PathPer + "/storage/list",
    method: "get",
    params: query,
  });
}
/**
 * 基础服务费详情
 */
export function serviceDetailInfo(query) {
  return fetch({
    url: PathPer + "/service/list",
    method: "get",
    params: query,
  });
}
/**
 * 质保金详情
 */
export function deoDetailInfo(query) {
  return fetch({
    url: PathPer + "/deo/qa",
    method: "get",
    params: query,
  });
}
/**
 * 发货计划的总单数--供应商
 */
export function supplierPlanCountInfo(query) {
  return fetch({
    url: PathPer + "/plan/count",
    method: "get",
    params: query,
  });
}
/**
 * 优先发货数量--供应商
 */
export function supplierPriorityCountInfo(query) {
  return fetch({
    url: PathPer + "/co/supplier/count",
    method: "get",
    params: query,
  });
}
/**
 * 优先发货清单--供应商
 */
export function supplierPriorityListInfo(query) {
  return fetch({
    url: PathPer + "/co/supplier/priorityList",
    method: "get",
    params: query,
  });
}
/**
 * 补货数量--供应商
 */
export function supplierReplenishmentCountInfo(query) {
  return fetch({
    url: PathPer + "/cr/supplier/count",
    method: "get",
    params: query,
  });
}
/**
 * 补货清单--供应商
 */
export function supplierReplenishmentListInfo(query) {
  return fetch({
    url: PathPer + "/cr/supplier/fp",
    method: "get",
    params: query,
  });
}
/**
 * 获取所有车型

 */
export function vehicleListInfo(query) {
  return fetch({
    url: PathPer + "/vehicle/list",
    method: "get",
    params: query,
  });
}
/**
 * 发货计划列表
 */
export function planListInfo(query) {
  return fetch({
    url: PathPer + "/plan/list",
    method: "get",
    params: query,
  });
}
/**
 * 未发货清单(供应商、运营)
 */
export function unshippedListInfo(query) {
  return fetch({
    url: PathPer + "/so/unshippedList",
    method: "get",
    params: query,
  });
}
/**
 * 创建发货计划
 */
export function createPlanInfo(query) {
  return fetch({
    url: PathPer + "/plan/create",
    method: "post",
    params: query,
  });
}
/**
 * 添加发货单明细
 */
export function addDetailInfo(query) {
  return fetch({
    url: PathPer + "/sio/addDetail",
    method: "post",
    params: query,
  });
}
/**
 * 移除发货明细
 */
export function deleteDetailInfo(query) {
  return fetch({
    url: PathPer + "/sio/deleteDetail",
    method: "post",
    params: query,
  });
}
/**
 * 客户订单支付
 */
export function paidInfo(query) {
  return fetch({
    url: PathPer + "/order/paid",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: query,

  });
}

/**
 * 获取客户默认地址
 */
export function defaultAddInfo(query) {
  return fetch({
    url: PathPer + "/address/customer/default",
    method: "get",
    params: query,
  });
}
/**
 * 未发货商品统计
 */
export function unshippedDetailInfo(query) {
  return fetch({
    url: PathPer + "/so/unshippedInfo",
    method: "get",
    params: query,
  });
}
/**
 * 添加发货单明细
 */
export function addPlanDetailInfo(query) {
  return fetch({
    url: PathPer + "/plan/addDetail",
    method: "get",
    params: query,
  });
}
/**
 * 移除发货明细
 */
export function delPlanDetailInfo(query) {
  return fetch({
    url: PathPer + "/plan/deleteDetail",
    method: "get",
    params: query,
  });
}
/**
 * 拣货车
 */
export function pickdetailListInfo(query) {
  return fetch({
    url: PathPer + "/plan/detailList",
    method: "get",
    params: query,
  });
}
/**
 * 拣货车数量
 */
export function pickdetailCountInfo(query) {
  return fetch({
    url: PathPer + "/plan/detailCount",
    method: "get",
    params: query,
  });
}
/**
 * 确认创建
 */
export function planConfirmInfo(query) {
  return fetch({
    url: PathPer + "/plan/verify",
    method: "post",
    params: query,
  });
}
/**
 * 发货通知数
 */
export function applyPlanCountInfo(query) {
  return fetch({
    url: PathPer + "/co/apply/count",
    method: "get",
    params: query,
  });
}
/**
 * 发货通知页
 */
export function applyPlanInfo(query) {
  return fetch({
    url: PathPer + "/co/apply",
    method: "get",
    params: query,
  });
}
/**
 * 确认创建发货通知
 */
export function createApplyInfo(query) {
  return fetch({
    url: PathPer + "/co/createApply",
    method: "post",
    params: query,
  });
}
/**
 * 发货通知列表
 */
export function applyListInfo(query) {
  return fetch({
    url: PathPer + "/co/apply/list",
    method: "get",
    params: query,
  });
}
/**
 * 去支付（支付前置操作）
 */
export function prePaidInfo(query) {
  return fetch({
    url: PathPer + "/order/prePaid",
    method: "post",
    params: query,
  });
}
/*******供货协议**********/
/**
 * 供货协议列表
 */
export function supplyListInfo(query) {
  return fetch({
    url: PathPer + "/supply/list",
    method: "get",
    params: query,
  });
}
/**
 * 供货协议详情
 */
export function supplyDetailInfo(query) {
  return fetch({
    url: PathPer + "/supply/findById",
    method: "get",
    params: query,
  });
}
/**
 * 新建供货协议
 */
export function saveSupplyInfo(query) {
  return fetch({
    url: PathPer + "/supply/save",
    method: "post",
    params: query,
  });
}
/**
 * 协议商品列表
 */
export function supplyProductsInfo(query) {
  return fetch({
    url: PathPer + "/supply/products",
    method: "get",
    params: query,
  });
}
/**
 * 拣货车数量
 */
export function supplyDetailsCountInfo(query) {
  return fetch({
    url: PathPer + "/supply/details/count",
    method: "get",
    params: query,
  });
}
/**
 * 拣货车
 */
export function supplyDetailsInfo(query) {
  return fetch({
    url: PathPer + "/supply/details",
    method: "get",
    params: query,
  });
}
/**
 * 删除拣货车明细

 */
export function supplyDelDetailsInfo(query) {
  return fetch({
    url: PathPer + "/supply/delDetail",
    method: "post",
    params: query,
  });
}
/**
 * 加入协议清单
 */
export function supplyAddDetailInfo(query) {
  return fetch({
    url: PathPer + "/supply/addDetail",
    method: "post",
    params: query,
  });
}
/**
 * 确认创建

 */
export function supplyConfirmInfo(query) {
  return fetch({
    url: PathPer + "/supply/confirm",
    method: "post",
    params: query,
  });
}
/**
 * 取消协议
 */
export function supplyCancleInfo(query) {
  return fetch({
    url: PathPer + "/supply/cancel",
    method: "post",
    params: query,
  });
}
/**
 * 终止协议
 */
export function supplyStopInfo(query) {
  return fetch({
    url: PathPer + "/supply/terminate",
    method: "post",
    params: query,
  });
}
/**
 * 确认协议
 */
export function supplyVerifyInfo(query) {
  return fetch({
    url: PathPer + "/supply/verify",
    method: "post",
    params: query,
  });
}
/**
 * 我的-供货协议
 */
export function mySupplyInfo(query) {
  return fetch({
    url: PathPer + "/supply/mySupply",
    method: "get",
    params: query,
  });
}

/*******FBX发货**********/

/**
 *FBX发货通知列表
 */
export function receiveListInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/list",
    method: "get",
    params: query,
  });
}
/**
 *FBX发货通知详情
 */
export function receiveDetailInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/findById",
    method: "get",
    params: query,
  });
}
/**
 *创建FBX发货通知
 */
export function receiveCreateInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/create",
    method: "post",
    params: query,
  });
}
/**
 *FBX未发货协议清单
 */
export function unReceiveDetailInfo(query) {
  return fetch({
    url: PathPer + "/supply/unReceiveDetail",
    method: "get",
    params: query,
  });
}
/**
 *FBX未发货协议清单
 */
export function supplyUnshippedInfo(query) {
  return fetch({
    url: PathPer + "/supply/unshippedInfo",
    method: "get",
    params: query,
  });
}
/**
 *拣货车数量
 */
export function fbxDetailsCountInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/details/count",
    method: "get",
    params: query,
  });
}
/**
 *加入FBX发货通知
 */
export function fbxAddDetailInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/addDetail",
    method: "post",
    params: query,
  });
}
/**
 *拣货车
 */
export function fbxPickDetailInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/details",
    method: "get",
    params: query,
  });
}
/**
 *拣货车统计
 */
export function fbxPickReceiveInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/receiveInfo",
    method: "get",
    params: query,
  });
}
/**
 *删除FBX发货通知明细
 */
export function delFbxPickInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/delDetail",
    method: "post",
    params: query,
  });
}
/**
 *上传库存照片
 */
export function uploadStockInfo(files) {
  return fetch({
    url: PathPer + "/receive/upload/stock",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: files,
  });
}
/**
 *提交申请
 */
export function fbxCommitInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/commit",
    method: "post",
    params: query,
  });
}
/**
 *上传装车照片
 */
export function uploadLoadInfo(files) {
  return fetch({
    url: PathPer + "/receive/upload/load",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: files,
  });
}
/**
 *FBX发货通知上传运输证
 */
export function uploadCertInfo(files) {
  return fetch({
    url: PathPer + "/receive/upload/certificate",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: files,
  });
}
/**
 *确认发货
 */
export function fbxVerifyInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/verify",
    method: "post",
    params: query,
  });
}

/*******FBM发货**********/

/**
 *FBM发货通知列表
 */
export function receiveListFbmInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbm/list",
    method: "get",
    params: query,
  });
}
/**
 *创建FBM发货通知
 */
export function receiveCreateFbmInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbm/create",
    method: "post",
    params: query,
  });
}
/**
 *入库过账
 */
export function fbmCommitInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbm/posting",
    method: "post",
    params: query,
  });
}

// /********************** 对账单*******************/
// /**
//  *对账单列表
//  */
// export function billListInfo(query) {
//   return fetch({
//     url: PathPer + "/bill/list",
//     method: "get",
//     params: query,
//   });
// }
// /**
//  *对账单详情
//  */
// export function billDetailInfo(query) {
//   return fetch({
//     url: PathPer + "/bill/detail",
//     method: "get",
//     params: query,
//   });
// }

/********************** 仓库清单*******************/

/***** 发货****/
/**
 *待装车列表
 */
export function loadingListInfo(query) {
  return fetch({
    url: PathPer + "/plan/loadingList",
    method: "get",
    params: query,
  });
}
/**
 *待装车详情
 */
export function loadingDetailInfo(query) {
  return fetch({
    url: PathPer + "/plan/loadingDetail",
    method: "get",
    params: query,
  });
}
/**
   *修改库位数量

   */
export function editDetailInfo(query) {
  return fetch({
    url: PathPer + "/plan/editDetail",
    method: "post",
    params: query,
  });
}
/**
 *补货清单
 */
export function shrListInfo(query) {
  return fetch({
    url: PathPer + "/plan/shr",
    method: "get",
    params: query,
  });
}
/**
   *拣货车

   */
export function extraListInfo(query) {
  return fetch({
    url: PathPer + "/plan/extra/list",
    method: "get",
    params: query,
  });
}
/**
 *拣货车数量
 */
export function extraListCountInfo(query) {
  return fetch({
    url: PathPer + "/plan/extra/count",
    method: "get",
    params: query,
  });
}
/**
 *补货商品加入发货计划
 */
export function addExtraInfo(query) {
  return fetch({
    url: PathPer + "/plan/addExtra",
    method: "post",
    params: query,
  });
}

/**
   *移除发货计划明细

   */
export function deleteExtraInfo(query) {
  return fetch({
    url: PathPer + "/plan/deleteDetail",
    method: "get",
    params: query,
  });
}

/**
 *确认过账
 */
export function deliveryInfo(query) {
  return fetch({
    url: PathPer + "/order/delivery",
    method: "post",
    params: query,
  });
}
/**
   *库位列表--发货计划

   */
export function locationListInfo(query) {
  return fetch({
    url: PathPer + "/location/list",
    method: "get",
    params: query,
  });
}


/*****收货****/

/**
   *收货列表

   */
export function receiveFbxListInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/list",
    method: "get",
    params: query,
  });
}
/**
 *暂不入库
 */
export function nopassListInfo(query) {
  return fetch({
    url: PathPer + "/receive/nopass",
    method: "post",
    params: query,
  });
}
/**
 *拒绝申请
 */
export function refuseInfo(query) {
  return fetch({
    url: PathPer + "/receive/refuse",
    method: "post",
    params: query,
  });
}
/**
 *确认接收
 */
export function receiveInfo(query) {
  return fetch({
    url: PathPer + "/receive/receive",
    method: "post",
    params: query,
  });
}

/**
 *收货详情
 */
export function receiveFbxDetailInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/findById",
    method: "get",
    params: query,
  });
}

/**
 *修改库位
 */
export function editLocationInfo(query) {
  return fetch({
    url: PathPer + "/receive/editLocation",
    method: "post",
    params: query,
  });
}

/**
 *入库过账
 */
export function receivePostingInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/posting",
    method: "post",
    params: query,
  });
}
/**
 *拒绝--财务审核
 */
export function verifyUnPaidInfo(query) {
  return fetch({
    url: PathPer + "/order/verifyUnPaid",
    method: "post",
    params: query,
  });
}
/**
 *通过-财务审核
 */
export function verifyPaidInfo(query) {
  return fetch({
    url: PathPer + "/order/verifyPaid",
    method: "post",
    params: query,
  });
}

/**
 *客户列表
 */
export function addressListClientInfo(query) {
  return fetch({
    url: PathPer + "/address/list",
    method: "get",
    params: query,
  });
}
/**
   *创建发货计划

   */
export function planFbxInfo(query) {
  return fetch({
    url: PathPer + "/plan/fbx",
    method: "post",
    params: query,
  });
}
/**
 *列表
 */
export function orderFinanceInfo(query) {
  return fetch({
    url: PathPer + "/order/finance",
    method: "get",
    params: query,
  });
}
/**
 *查询支付凭证
 */
export function orderPayPicInfo(query) {
  return fetch({
    url: PathPer + "/order/payPic",
    method: "get",
    params: query,
  });
}
/**
 *可补货列表
 */
export function rpListInfo(query) {
  return fetch({
    url: PathPer + "/rp/list",
    method: "get",
    params: query,
  });
}
/**
 *库存列表
 */
export function rpStocksInfo(query) {
  return fetch({
    url: PathPer + "/rp/stocks",
    method: "get",
    params: query,
  });
}
/**
 *添加可补货商品
 */
export function rpSaveInfo(query) {
  return fetch({
    url: PathPer + "/rp/save",
    method: "post",
    params: query,
  });
}
/**
 *移除
 */
export function rpDelInfo(query) {
  return fetch({
    url: PathPer + "/rp/del",
    method: "post",
    params: query,
  });
}
/**
 * 出入库明细
 */
export function stocksDetailInfo(query) {
  return fetch({
    url: PathPer + "/stockIo/list",
    method: "get",
    params: query,
  });
}
/**
 * 物流信息保存
 */
export function planLogisticsInfo(query) {
  return fetch({
    url: PathPer + "/plan/logisticsInfo",
    method: "post",
    params: query,
  });
}
/**
 * 运输信息审核列表
 */
export function certListInfo(query) {
  return fetch({
    url: PathPer + "/plan/cert/list",
    method: "get",
    params: query,
  });
}

/**
 * 运营审核运输证
 */
export function certVerifyInfo(query) {
  return fetch({
    url: PathPer + "/plan/cert/verify",
    method: "get",
    params: query,
  });
}
/**
 * 上传运输证信息
 */
export function certUploadInfo(files) {
  return fetch({
    url: PathPer + "/plan/upload/certificate",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: files,
  });
}

/**
 * 运营端查询是否有待收货
 */
export function fbxRcountInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/rcount",
    method: "get",
    params: query,
  });
}
/**
 * 差额结算
 */
export function balancePaidInfo(files) {
  return fetch({
    url: PathPer + "/order/balancePaid",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: files,
  });
}
/**
 * 删除库位
 */
export function removeLocationInfo(query) {
  return fetch({
    url: PathPer + "/receive/removeLocation",
    method: "post",
    params: query,
  });
}
/**
 * 更换发货仓库
 */
export function replaceWarehouseInfo(query) {
  return fetch({
    url: PathPer + "/plan/replaceWarehouse",
    method: "post",
    params: query,
  });
}
/**
 * 未送
 */
export function clearLocationInfo(query) {
  return fetch({
    url: PathPer + "/receive/clearLocation",
    method: "post",
    params: query,
  });
}
/**
 * 仓区列表
 */
export function planAreaInfo(query) {
  return fetch({
    url: PathPer + "/plan/areas",
    method: "get",
    params: query,
  });
}
/**
 * 库位已拣
 */
export function planPickInfo(query) {
  return fetch({
    url: PathPer + "/plan/picked",
    method: "post",
    params: query,
  });
}
/**
 * 取消库位已拣
 */
export function planCancelInfo(query) {
  return fetch({
    url: PathPer + "/plan/cancel",
    method: "post",
    params: query,
  });
}
/**
 * 年度订单明细
 */
export function coAnnualInfo(query) {
  return fetch({
    url: PathPer + "/co/annual",
    method: "get",
    params: query,
  });
}
/**
 * 年度订单产品明细
 */
export function coAnnualDetailInfo(query) {
  return fetch({
    url: PathPer + "/co/annual/detail",
    method: "get",
    params: query,
  });
}
/**
 * 年度订单明细
 */
export function soAnnualInfo(query) {
  return fetch({
    url: PathPer + "/so/annual",
    method: "get",
    params: query,
  });
}
/**
 * 年度订单产品明细
 */
export function soAnnualDetailInfo(query) {
  return fetch({
    url: PathPer + "/so/annual/detail",
    method: "get",
    params: query,
  });
}
/**
 * 发货计划信息
 */
export function planDetailInfo(query) {
  return fetch({
    url: PathPer + "/plan/detailInfo",
    method: "get",
    params: query,
  });
}
/**
 * 更换发货车型
 */
export function planReplaceVehicleInfo(query) {
  return fetch({
    url: PathPer + "/plan/replaceVehicle",
    method: "post",
    params: query,
  });
}
/**
 *查询产品采购公告
 */
export function coLastdInfo(query) {
  return fetch({
    url: PathPer + '/co/lasted',
    method: 'get',
    params: query
  })
}
/**
 *可发货公司仓库列表
 */
export function coStoreHouseInfo(query) {
  return fetch({
    url: PathPer + "/co/shippable/storeHouse",
    method: "get",
    params: query,
  });
}
/**
 *发货计划数量
 */
export function planSuppCountInfo(query) {
  return fetch({
    url: PathPer + "/plan/supplier/count",
    method: "get",
    params: query,
  });
}
/**
 *更换发货商家
 */
export function replaceSupplieInfo(query) {
  return fetch({
    url: PathPer + "/plan/replaceSupplier",
    method: "post",
    params: query,
  });
}
/**
 *更换收货地址
 */
export function replaceReceiverInfo(query) {
  return fetch({
    url: PathPer + "/plan/replaceReceiver",
    method: "post",
    params: query,
  });
}
/**
 *修改发货计划
 */
export function planUpdateInfo(query) {
  return fetch({
    url: PathPer + "/plan/update",
    method: "post",
    params: query,
  });
}
/**
 *通知客服
 */
export function planNotifyInfo(query) {
  return fetch({
    url: PathPer + "/plan/notify",
    method: "post",
    params: query,
  });
}
/**
 *对账单列表
 */
export function earningListInfo(query) {
  return fetch({
    url: PathPer + "/earning/list",
    method: "get",
    params: query,
  });
}
/**
 *详情
 */
export function earningDetailInfo(query) {
  return fetch({
    url: PathPer + "/earning/findById",
    method: "get",
    params: query,
  });
}
/**
 *退货原因列表
 */
export function remarkListInfo(query) {
  return fetch({
    url: PathPer + "/remark/list",
    method: "get",
    params: query,
  });
}
/**
 *售后方式
 */
export function returnsListInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/method",
    method: "get",
    params: query,
  });
}
/**
 *预览退货
 */
export function returnsPreviewInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/preview",
    method: "get",
    params: query,
  });
}
/**
 *售后数量
 */
export function returnsCountInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/count",
    method: "get",
    params: query,
  });
}
/**
 * 创建退货
 */
export function returnsCreateInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/create",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: query,
  });
}
/**
 *售后列表
 */
export function returnsListPageInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/page",
    method: "get",
    params: query,
  });
}
/**
 * 上传运输证信息-售后
 */
export function certUploadReturnsInfo(files) {
  return fetch({
    url: PathPer + "/order/returns/upload/certificate",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: files,
  });
}
/**
 *无证先发货
 */
export function returnsVerifyInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/cert/verify",
    method: "get",
    params: query,
  });
}
/**
 *拒绝退款
 */
export function returnsRefuseInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/refuse",
    method: "post",
    params: query,
  });
}
/**
 *同意退款
 */
export function returnsAgreeInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/agree",
    method: "post",
    params: query,
  });
}
/**
 *确认退货
 */
export function returnsConfirmInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/confirm",
    method: "post",
    params: query,
  });
}
/**
 *查询是否全部已检
 */
export function orderPickInfo(query) {
  return fetch({
    url: PathPer + "/order/picked",
    method: "get",
    params: query,
  });
}
/**
 *修改退款单价
 */
export function orderSetPriceInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/setPrice",
    method: "post",
    params: query,
  });
}
/**
 * 退款支付
 */
export function refundInfo(query) {
  return fetch({
    url: PathPer + "/order/refund",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: query,

  });
}
/**
 *退款方式
 */
export function refundMethodInfo(query) {
  return fetch({
    url: PathPer + "/order/refund/method",
    method: "get",
    params: query,
  });
}
/**
 *关结明细
 */
export function closeSubInfo(query) {
  return fetch({
    url: PathPer + "/co/closeSub",
    method: "post",
    params: query,
  });
}
/**
 *查询客户余额
 */
export function orderBalanceInfo(query) {
  return fetch({
    url: PathPer + "/order/balance",
    method: "get",
    params: query,
  });
}
/**
 *订单关结预览
 */
export function finishPreviewInfo(query) {
  return fetch({
    url: PathPer + "/co/finishPreview",
    method: "get",
    params: query,
  });
}
/**
 *收益统计
 */
export function earningTotalInfo(query) {
  return fetch({
    url: PathPer + "/earning/total",
    method: "get",
    params: query,
  });
}
/**
 *可送货仓库列表
 */
export function receivingListInfo(query) {
  return fetch({
    url: PathPer + "/cp/arrange/receiving/list",
    method: "get",
    params: query,
  });
}
/**
 *协同方式
 */
export function arrangeMethodInfo(query) {
  return fetch({
    url: PathPer + "/cp/arrange/method",
    method: "get",
    params: query,
  });
}
/**
 *协同详情
 */
export function arrangeDetailInfo(query) {
  return fetch({
    url: PathPer + "/cp/arrange/detail",
    method: "get",
    params: query,
  });
}
/**
 *更换协同目标
 */
export function arrangeChangeTargetInfo(query) {
  return fetch({
    url: PathPer + "/cp/arrange/changeTarget",
    method: "get",
    params: query,
  });
}
/**
 *发送发货单下载链接到客户邮箱
 */
export function sendExportUrlInfo(query) {
  return fetch({
    url: PathPer + "/deo/sendExportUrl",
    method: "post",
    params: query,
  });
}
/**
 *删除发货计划
 */
export function planDelInfo(query) {
  return fetch({
    url: PathPer + "/plan/delete",
    method: "post",
    params: query,
  });
}
/**
 *修改数量
 */
export function modifyDetailInfo(query) {
  return fetch({
    url: PathPer + "/supply/modifyDetail",
    method: "post",
    params: query,
  });
}
/**
 *导入fbx发货计划
 */
export function fbxImportInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/import",
    method: "post",
    params: query,
  });
}
/**
 *发货日期范围列表
 */
export function receiveRangeListInfo(query) {
  return fetch({
    url: PathPer + "/receive/deliveryDate/rangeList",
    method: "get",
    params: query,
  });
}
/**
 *发车审核列表
 */
export function departCheckListInfo(query) {
  return fetch({
    url: PathPer + "/plan/departCheck/list",
    method: "get",
    params: query,
  });
}
/**
 *发车审核
 */
export function departCheckPlanInfo(query) {
  return fetch({
    url: PathPer + "/plan/departCheck",
    method: "post",
    params: query,
  });
}
/**
 *发车放行列表
 */
export function departPassListInfo(query) {
  return fetch({
    url: PathPer + "/plan/departPass/list",
    method: "get",
    params: query,
  });
}
/**
 *放行
 */
export function departPassPassInfo(query) {
  return fetch({
    url: PathPer + "/plan/departPass/pass",
    method: "post",
    params: query,
  });
}
/**
 *创建FBX退货
 */
export function returnsAddInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/add",
    method: "post",
    params: query,
  });
}
/**
 *FBX退货单详情
 */
export function returnsFindInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/findById",
    method: "get",
    params: query,
  });
}
/**
 *添加退货明细
 */
export function returnsAddDetailInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/addDetail",
    method: "post",
    params: query,
  });
}
/**
 *拣货车数量
 */
export function detailsCountInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/details/count",
    method: "get",
    params: query,
  });
}
/**
 *拣货车
 */
export function fbxDetailsInfo(query) {
  return fetch({
    url: PathPer + "/receive/fbx/details",
    method: "get",
    params: query,
  });
}
/**
 *删除明细
 */
export function fbxDelDetailInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/delDetail",
    method: "post",
    params: query,
  });
}
/**
 *确认创建
 */
export function returnsCommitInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/commit",
    method: "post",
    params: query,
  });
}
/**
 *列表（待退货）
 */
export function fbxReturnsListInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/list",
    method: "get",
    params: query,
  });
}
/**
 *列表（待装车）
 */
export function fbxReturnsLoadingListInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/loadingList",
    method: "get",
    params: query,
  });
}
/**
 *FBX退货单装车详情
 */
export function fbxReturnsLoadingDetailInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/loadingDetail",
    method: "get",
    params: query,
  });
}
/**
 *查询库位
 */
export function fbxAreasInfo(query) {
  return fetch({
    url: PathPer + "/receive/areas",
    method: "get",
    params: query,
  });
}
/**
 *已拣
 */
export function fbxPickedInfo(query) {
  return fetch({
    url: PathPer + "/receive/picked",
    method: "post",
    params: query,
  });
}
/**
 *未拣
 */
export function fbxCancelInfo(query) {
  return fetch({
    url: PathPer + "/receive/cancel",
    method: "post",
    params: query,
  });
}
/**
 *新增库位时，查询库位
 */
export function fbxLocationRodListInfo(query) {
  return fetch({
    url: PathPer + "/location/rod/list",
    method: "get",
    params: query,
  });
}
/**
 *修改库位
 */
export function fbxEditLocationInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/editLocation",
    method: "post",
    params: query,
  });
}
/**
 *修改物流信息
 */
export function fbxEditLogisticsInfoInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/logisticsInfo",
    method: "post",
    params: query,
  });
}
/**
 *确认过账（FBX退货）
 */
export function fbxReturnspostingInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/posting",
    method: "post",
    params: query,
  });
}
/**
 *查询是否全部已拣
 */
export function fbxReturnsAllPickedInfo(query) {
  return fetch({
    url: PathPer + "/receive/returns/allPicked",
    method: "get",
    params: query,
  });
}
/**
 *先发车
 */
export function fbxPlanPreDepartInfo(query) {
  return fetch({
    url: PathPer + "/plan/preDepart",
    method: "post",
    params: query,
  });
}
/**
 *再次购买
 */
export function coCopyInfo(query) {
  return fetch({
    url: PathPer + "/co/copy",
    method: "post",
    params: query,
  });
}
/**
 *再次购买预览
 */
export function coPrecopyInfo(query) {
  return fetch({
    url: PathPer + "/co/preCopy",
    method: "post",
    params: query,
  });
}

/**
 *可换货列表
 */
export function exchangeListInfo(query) {
  return fetch({
    url: PathPer + "/plan/exchangeList",
    method: "get",
    params: query,
  });
}
/**
 *需支付金额
 */
export function orderUnpaidAmountInfo(query) {
  return fetch({
    url: PathPer + "/order/unpaidAmount",
    method: "get",
    params: query,
  });
}
/**
 *修改抹零金额
 */
export function eidtFreeInfo(query) {
  return fetch({
    url: PathPer + "/order/eidtFree",
    method: "post",
    params: query,
  });
}
/**
 * 客户新增/重新上传预付款（H5）
 */
export function paymentAddReceiptInfo(files) {
  return fetch({
    url: PathPer + "/payment/h5/addReceipt",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: files,
  });
}
/**
 *审核预收款（H5）
 */
export function paymentAuditReceiptInfo(query) {
  return fetch({
    url: PathPer + "/payment/h5/auditReceipt",
    method: "post",
    params: query,
  });
}
/**
 *预付款列表（H5）
 */
export function paymentH5CrsListInfo(query) {
  return fetch({
    url: PathPer + "/payment/h5/crs",
    method: "get",
    params: query,
  });
}
/**
 *预付款详情（H5）
 */
export function paymentH5CrDetailInfo(query) {
  return fetch({
    url: PathPer + "/payment/h5/crDetail",
    method: "get",
    params: query,
  });
}
/**
 *删除凭证
 */
export function paymentDelPicInfo(query) {
  return fetch({
    url: PathPer + "/payment/h5/delPic",
    method: "post",
    params: query,
  });
}
/**
 *预付款金额统计
 */
export function needAuditInfo(query) {
  return fetch({
    url: PathPer + "/payment/h5/needAudit",
    method: "get",
    params: query,
  });
}
/**
 *转余额
 */
export function refundToBalanceInfo(query) {
  return fetch({
    url: PathPer + "/payment/refundToBalance",
    method: "post",
    params: query,
  });
}
/**
 *直接退款(PC端)
 */
export function paymentRefundInfo(query) {
  return fetch({
    url: PathPer + "/payment/customer/refund",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: query,
  });
}
/**
 *退款列表
 */
export function needRefundListInfo(query) {
  return fetch({
    url: PathPer + "/payment/h5/needRefund",
    method: "get",
    params: query,
  });
}
/**
 * 手动添加预收款
 */
export function paymentAddReceiptOpInfo(query) {
  return fetch({
    url: PathPer + "/payment/customer/addReceipt",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: query,

  });
}
/**
 *变换责任方
 */
export function returnsChangeRespInfo(query) {
  return fetch({
    url: PathPer + "/order/returns/changeResp",
    method: "post",
    params: query,
  });
}
/**
 *获取客户订单年度汇总
 */
export function summaryCuInfo(query) {
  return fetch({
    url: PathPer + "/order/summary/cu",
    method: "get",
    params: query,
  });
}
/**
 *获取客户订单明细年度汇总
 */
export function summaryCuDetailInfo(query) {
  return fetch({
    url: PathPer + "/order/summary/cu/detail",
    method: "get",
    params: query,
  });
}
/**
 *获取供应商订单年度汇总
 */
export function summarySuInfo(query) {
  return fetch({
    url: PathPer + "/order/summary/su",
    method: "get",
    params: query,
  });
}
/**
 *获取供应商订单明细年度汇总
 */
export function summarySuDetailInfo(query) {
  return fetch({
    url: PathPer + "/order/summary/su/detail",
    method: "get",
    params: query,
  });
}
/**
 *客户年度订单收款分页
 */
export function summaryCuPaymentDetailInfo(query) {
  return fetch({
    url: PathPer + "/order/summary/payment/customer/list",
    method: "get",
    params: query,
  });
}
/**
 *供应商年度订单付款分页
 */
export function summarySuPaymentDetailInfo(query) {
  return fetch({
    url: PathPer + "/order/summary/payment/supplier/list",
    method: "get",
    params: query,
  });
}
/**
 *客户订单51代付
 */
export function order51PaidInfo(query) {
  return fetch({
    url: PathPer + "/order/51paid",
    method: "post",
    params: query,
  });
}
/**
 *修改订单支付信息
 */
export function editPaidInfo(query) {
  return fetch({
    url: PathPer + "/order/editPaid",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: query,
  });
}
/**
 *优惠券列表(运营端)
 */
 export function couponListInfo(query) {
  return fetch({
    url: PathPer + "/coupon/list",
    method: "get",
    params: query,
  });
}
/**
 *优惠券详情(客户端）
 */
 export function couponFindInfo(query) {
  return fetch({
    url: PathPer + "/coupon/findById",
    method: "get",
    params: query,
  });
}
/**
 *领取优惠券(客户端)
 */
 export function addCouponCustomerInfo(query) {
  return fetch({
    url: PathPer + "/coupon/addCouponCustomer",
    method: "post",
    params: query,
  });
}
/**
 *优惠券列表(客户端)
 */
 export function customerCouponsListInfo(query) {
  return fetch({
    url: PathPer + "/coupon/customer/coupons",
    method: "get",
    params: query,
  });
}
/**
 *修改优惠券(客户端)
 */
 export function customerUpdateInfo(query) {
  return fetch({
    url: PathPer + "/coupon/update",
    method: "get",
    params: query,
  });
}
/**
 *优惠券数量(客户端)
 */
 export function customerCouponCountInfo(query) {
  return fetch({
    url: PathPer + "/coupon/customer/count",
    method: "get",
    params: query,
  });
}
/**
 *修改订单明细数量
 */
 export function coEditCountInfo(query) {
  return fetch({
    url: PathPer + "/co/editCount",
    method: "post",
    params: query,
  });
}
/**
 *批量加入购物车
 */
 export function saveBatchInfo(query) {
  return fetch({
    url: PathPer + "/sc/saveBatch",
    method: "post",
    params: query,
  });
}
/**
 *判断是否有可领取的优惠券
 */
 export function couponAvailableInfo(query) {
  return fetch({
    url: PathPer + "/coupon/available",
    method: "get",
    params: query,
  });
}