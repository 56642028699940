export default [
  {
    path: "/uploadEntryClient", //上传资料
    name: "UploadEntryClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/uploadEntry"),
  },
  {
    path: "/uploadEntryedClient", //上传资料
    name: "UploadEntryedClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/uploadEntryed"),
  },
  {
    path: "/clientHome", //首页
    name: "clientHome",
    meta: {
      navBarFlag: false,
      tabBarFlag: true,
      requiredAuth: true,
      deepth: 0.5
    },
    component: () => import("@pages/client/clientHome"),
  },
  {
    path: "/mine", //我的
    name: "Mine",
    meta: {
      navBarFlag: false,
      tabBarFlag: true,
      requiredAuth: true,
      deepth: 0.5 // 定义路由的层级
    },
    component: () => import("@pages/client/mine"),
  },
  {
    path: "/productDetail", //商品详情页
    name: "productDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true, 
    },
    component: () => import("@pages/client/productDetail"),
  },
  {
    path: "/reviews", //商品评价页面
    name: "Reviews",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/reviews"),
  },
  {
    path: "/suppClassificationOp", //商品分类页
    name: "SuppClassificationOp",
    meta: {
      navBarFlag: false,
      tabBarFlag: true,
      requiredAuth: false,
      // keepAlive:true,
      // deepth: 1
    },
    component: () => import("@pages/client/suppClassificationOp"),
  },
  {
    path: "/shopCart", //购物车cheche
    name: "ShopCart",
    meta: {
      tabBarFlag: true,
      requiredAuth: true,
    },
    component: () => import("@pages/client/shopCart"),
  },
  {
    path: "/confirmOrder", //订单确认
    name: "ConfirmOrder",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/confirmOrder"),
  },
  {
    path: "/addAddress", //新增收货地址
    name: "AddAddress",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/addAddress"),
  },
  {
    path: "/manageAddress", //管理收货地址
    name: "ManageAddress",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/manageAddress"),
  },
  {
    path: "/addRemarks", //添加备注
    name: "AddRemarks",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/addRemarks"),
  },
  {
    path: "/orderPage", //订单页
    name: "OrderPage",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive: true, //需要缓存的页面
      deepth: 1
    },
    component: () => import("@pages/client/orderPage"),
  },
  {
    path: "/toBePaid", //待支付页
    name: "ToBePaid",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/toBePaid"),
  },
  {
    path: "/cancled", //已取消页
    name: "Cancled",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth:1.5
    },
    component: () => import("@pages/client/cancled"),
  },
  {
    path: "/waitingForShipment", //等待发货页
    name: "waitingForShipment",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/waitingForShipment"),
  },
  {
    path: "/waitingForLoading", //等待装车页
    name: "WaitingForLoading",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/waitingForLoading"),
  },
  {
    path: "/waitingForReceipt", //等待收货页
    name: "WaitingForReceipt",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/waitingForReceipt"),
  },
  {
    path: "/completed", //已完成页
    name: "completed",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/completed"),
  },
  {
    path: "/priorityShippingList", //优先发货清单
    name: "PriorityShippingList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/priorityShippingList"),
  },
  {
    path: "/replenishmentList", //补货清单
    name: "RriorityShippingList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/replenishmentList"),
  },
  {
    path: "/replenishmentInventTable", //可补货库存表
    name: "ReplenishmentInventTable",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/replenishmentInventTable"),
  },

  {
    path: "/shippingPlanDetail", //发货计划详情
    name: "ShippingPlanDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/shippingPlanDetail"),
  },
  {
    path: "/bankTransfer", //银行转账
    name: "BankTransfer",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/bankTransfer"),
  },
  {
    path: "/paymentSuccess", //支付成功
    name: "PaymentSuccess",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/paymentSuccess"),
  },
  {
    path: "/shippingPlanClient", //发货计划列表
    name: "ShippingPlanClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/shippingPlan"),
  },
  {
    path: "/createShipmentPlanClient", //发货通知-确认创建
    name: "createShipmentPlanClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/createShipmentPlan"),
  },
  {
    path: "/createShippingPlanDetailClient", //发货通知详情
    name: "CreateShippingPlanDetailClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      // keepAlive: true, //需要缓存的页面
    },
    component: () => import("@pages/client/createShippingPlanDetail"),
  },
  {
    path: "/shopClient", //商家店铺
    name: "ShopClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/shop"),
  },
  {
    path: "/SearchResultShopCar", //购物车搜索
    name: "SearchResultShopcar",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/search/resultShop"),
  },
  {
    path: "/annualOrderDetails", //年度订单明细
    name: "AnnualOrderDetails",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth:0.5
    },
    component: () => import("@pages/client/annualOrderDetails"),
  },

  {
    path: "/annualOrderProductDetails", //年度订单产品明细
    name: "AnnualOrderProductDetails",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
     
    },
    component: () => import("@pages/client/annualOrderProductDetails"),
  },
  {
    path: "/annualPayProductDetails", //年度付款产品明细
    name: "annualPayProductDetails",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth:0.5
    },
    component: () => import("@pages/client/annualPayProductDetails"),
  },
  {
    path: "/likeList", //点赞列表
    name: "LikeList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/mine/likeList.vue"),
  },
  {
    path: "/favoriteList", //收藏列表
    name: "FavoriteList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/mine/favoriteList.vue"),
  },
  {
    path: "/setUpMineIndex", //设置
    name: "SetUpMineIndex",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/mine/setUpMine/index.vue"),
  },
  {
    path: "/setEmail", //设置邮箱
    name: "SetEmail",
    meta: {
      navBarFlag: true,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/mine/setUpMine/setEmail.vue"),
  },
  {
    path: "/brandZone", //品牌专区
    name: "BrandZone",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/brandZone/index.vue"),
  },
  {
    path: "/afterSalesIndex", //申请售后
    name: "AfterSalesIndex",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth: 0.5
    },
    component: () => import("@pages/client/afterSales/index.vue"),
  },
  {
    path: "/afterSalesList", //申请售后列表
    name: "AfterSalesList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive:true,
      deepth: 1
    },
    component: () => import("@pages/client/afterSales/list.vue"),
  },
  {
    path: "/afterSalesDetail", //售后详情
    name: "AfterSalesDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/afterSales/detail.vue"),
  },
  {
    path: "/afterSalesDetail1", //售后详情-上传
    name: "AfterSalesDetail1",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/afterSales/detail1.vue"),
  },
  {
    path: "/rankList", //排行榜
    name: "RankList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive: true, //需要缓存的页面
      deepth: 1
    },
    component: () => import("@pages/client/rankList"),
  },
  {
    path: "/vipShop", //会员店铺
    name: "VipShop",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
     
    },
    component: () => import("@pages/client/vipShop"),
  },
  {
    path: "/receivePaymentListMine", //我的预付款
    name: "ReceivePaymentListMine",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive: true, //需要缓存的页面
      deepth: 1
     
    },
    component: () => import("@pages/client/mine/receivePayment/receivePaymentList.vue"),
  },
  {
    path: "/addReceivement", //新增预付款
    name: "AddReceivement",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth: 1.5
    
     
    },
    component: () => import("@pages/client/mine/receivePayment/addReceivement.vue"),
  },
  {
    path: "/updateReceivement", //修改预付款
    name: "UpdateReceivement",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth: 0.5
     
    },
    component: () => import("@pages/client/mine/receivePayment/updateReceivement.vue"),
  },

  {
    path: "/aiChoice", //Ai帮你挑
    name: "AiChoice",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      // keepAlive: true, //需要缓存的页面
      // deepth: 1
    },
    component: () => import("@pages/client/aiChoice"),
  },
  {
    path: "/couponListClient", //优惠券列表
    name: "couponListClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    
    },
    component: () => import("@pages/client/promotion/couponList"),
  },
  {
    path: "/couponListFind", //优惠券列表---分享链接进来的单张
    name: "CouponListFind",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/promotion/couponListFind.vue"),
  },
  {
    path: "/voteList", //优惠券列表---分享链接进来的单张
    name: "VoteList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/voteList"),
  },
  {
    path: "/activityList", //优惠券列表---分享链接进来的单张
    name: "ActivityList",
    meta: {
      navBarFlag: false,
      tabBarFlag: true,
      requiredAuth: true,
    },
    component: () => import("@pages/client/activityList"),
  },
  {
    path: "/combinationSharingListClient", //组合分享列表-分享链接进来的预览
    name: "CombinationSharingListClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/client/combinationShare/list"),
  },

];
